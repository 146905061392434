// fetchOptionHeader.js
import { getIsInPwa } from '../resource/getUserAgent.js';

export const isClient = typeof window !== 'undefined';

let fetchOptionHeader = {};

const WHITE_LIST = [
  'X-Track',
  'X-Session-ID',
  'X-Client-ID',
  'X-Version',
  'X-AB',
  'X-Fingerprint-Oss-Id',
  'X-Fingerprint-Pro-Id',
  'Origin', // only for SSR
];

const UTM_LIST = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
];

const FLAVOR_UTM_LIST = [
  'pwa',
  'homescreen',
  '_5wag',
  '_mdm',
  'mdm',
  '_flavor',
];

export const UTM_WHITE_LIST = [...UTM_LIST, ...FLAVOR_UTM_LIST];

export const TRACK_UTM_LIST = [
  'affise_click_id',
  'affise_offer_id',
  'affise_time',
  'ao_time',
  'c_track_id',
  'c_aff_id',
  'c_off_id',
  'voluum', // accept voluum.*
  ...UTM_WHITE_LIST,
];

export const getTrackHeader = ({
  utmObject = {},
  trackObject = {},
  hostname = '',
} = {}) => {
  let xTrackArray = [];
  TRACK_UTM_LIST.forEach(key => {
    if (utmObject[key] && !FLAVOR_UTM_LIST.includes(key)) {
      xTrackArray.push(`${key}=${encodeURIComponent(utmObject[key])}`);
    }
  });

  const detectedHostname =
    (isClient ? window.location.hostname : null) || hostname || 'swag.live';
  // NO underscore `_` prefix in X-Track
  if (utmObject['_flavor'] && utmObject['_flavor'] !== detectedHostname) {
    xTrackArray.push(`flavor=${utmObject['_flavor']}`);
  }

  const isPwa = isClient ? getIsInPwa() : utmObject['pwa'];
  if (isPwa) {
    xTrackArray.push('pwa=1');
  }

  const isMdm = utmObject['mdm'];
  if (isMdm) {
    xTrackArray.push('mdm=1');
  }

  Object.keys(trackObject).forEach(trackKey => {
    xTrackArray.push(`${trackKey}=${trackObject[trackKey]}`);
  });
  return xTrackArray
    .filter(key => key)
    .sort()
    .join(';');
};

let mergedTrackObject = {};
let mergedUtmObject = {};
export const setTrackHeader = ({
  utmObject = {},
  trackObject = {},
  shouldMergeUtmObject = false,
  shouldMergeTrackObject = true,
} = {}) => {
  if (shouldMergeUtmObject) {
    mergedUtmObject = { ...mergedUtmObject, ...utmObject };
  } else {
    mergedUtmObject = utmObject;
  }
  if (shouldMergeTrackObject) {
    mergedTrackObject = { ...mergedTrackObject, ...trackObject };
  } else {
    mergedTrackObject = trackObject;
  }
  fetchOptionHeader['X-Track'] = getTrackHeader({
    utmObject: mergedUtmObject,
    trackObject: mergedTrackObject,
  });
};

export const getHeaders = () => {
  return fetchOptionHeader;
};

export const setHeader = ({ key, value }) => {
  if (WHITE_LIST.includes(key)) {
    fetchOptionHeader[key] = value;
  }
};

export const getHeader = key => {
  return key ? fetchOptionHeader[key] : fetchOptionHeader;
};

export const deleteHeader = ({ key }) => {
  if (key) {
    delete fetchOptionHeader[key];
  }
};

export const getCurrentUtm = ({ searchParams, existUtm = {} }) => {
  const shouldOverrideAllUtm = !!TRACK_UTM_LIST.filter(key => {
    return key.startsWith('utm_') && searchParams.get(key);
  }).length;

  const currentUtm = TRACK_UTM_LIST.reduce((prev, curr) => {
    if (FLAVOR_UTM_LIST.includes(curr)) {
      if (!searchParams.get(curr)) {
        if (existUtm[curr])
          return Object.assign(prev, { [curr]: existUtm[curr] });
        else return prev;
      }

      switch (curr) {
        case '_5wag':
          return Object.assign(prev, { ['_flavor']: 'city.cafe.browser' }); // _5wag is not a formal flavor
        case '_mdm':
        case 'mdm':
          return Object.assign(prev, { ['mdm']: true });
        case 'pwa':
        case 'homescreen':
          return Object.assign(prev, { ['pwa']: true });
        default: // '_flavor'
          return Object.assign(prev, {
            [curr]: searchParams.get(curr) || undefined,
          });
      }
    }

    if (curr.startsWith('utm_')) {
      if (!shouldOverrideAllUtm && !searchParams.get(curr) && !existUtm[curr])
        return prev;
      return Object.assign(prev, {
        [curr]: shouldOverrideAllUtm
          ? searchParams.get(curr) || undefined
          : existUtm[curr],
      });
    }

    let acceptableKeysWithPrefix = [],
      acceptableKeysObject = {};
    searchParams.forEach((value, key) => {
      if (key.startsWith(curr)) acceptableKeysWithPrefix.push(key);
    });
    if (acceptableKeysWithPrefix.length) {
      acceptableKeysWithPrefix.forEach(key => {
        Object.assign(acceptableKeysObject, {
          [key]: searchParams.get(key) || existUtm[key],
        });
      });
      return Object.assign(prev, acceptableKeysObject);
    }

    if (!searchParams.get(curr) && !existUtm[curr]) return prev;
    return Object.assign(prev, {
      [curr]: searchParams.get(curr) || existUtm[curr],
    });
  }, {});
  return currentUtm;
};

export const getPusherFetchHeaders = ({ token, clientId } = {}) => {
  const headers = Object.assign(
    {
      ...getHeaders(),
      'X-Client-ID': clientId,
    },
    token && { Authorization: `Bearer ${token}` }
  );

  // Use batch-authenticate to refresh ab token
  if (headers['X-AB']) delete headers['X-AB'];
  return headers;
};
